import { Marker } from "@/models/Marker";

const imgPath = "/assets/images/";
export const markers: Marker[] = [
  // 01 Augusta Viktoria rechte Lahnseite
  {
    title: "Augusta Viktoria rechte Lahnseite",
    desc: "Augusta Viktoria rechte Lahnseite",
    latLng: [-12, -131],
    icon: imgPath + "01/m/marker.png",
    iconSize: 250,
    img: { path: imgPath + "01/m/card.jpg", height: 250 },
    data: [
      {
        title: "3D Video",
        type: "VideoItem",
        desc: "",
        data: [
          {
            video: {
              src: imgPath + "01/v/av_rechte_lahnseite.mp4",
              poster: imgPath + "01/m/card.jpg",
            },
          },
        ],
      },
      {
        title: "Bildreihe Gesellschafter",
        type: "ImageSlider",
        desc: "Gesellschafter des Selters-Sprudel Augusta-Victoria",
        data: [
          {
            img: {
              path: imgPath + "01/s/" + "01.jpg",
              caption:
                "Gesellschafter der Selters-Sprudel Augusta-Victoria ca. 1902 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/s/" + "02.jpg",
              caption:
                "Karl Reinemer, Aufsichtsratsvorsitzender 1902 - 1909 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/s/" + "03.jpg",
              caption:
                "Abraham Fröhlich, Aufsichtsratsvorsitzender 1909 - 1933 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/s/" + "04.jpg",
              caption:
                "Gesellschafter und Betriebsleitung der Selters-Sprudel Augusta-Victoria 1910 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/s/" + "05.jpg",
              caption: "Aufsichtsrat 1927 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/s/" + "06.jpg",
              caption:
                "Selters-Sprudel Augusta-Victoria mit Gesellschaftern 1927 (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
        ],
      },
      {
        title: "Beriebsgebäude Bahnseite",
        type: "HoverImage",
        desc: "Vergleich früher zur Visualisierung",
        data: [
          {
            title: "Foto von 1905",
            desc: "Anlieferung des Kessels mit Dampfdom für die Dampfmaschine 1905 (Gemeindearchiv)",
            img: {
              path:
                imgPath +
                "01/h/S125u-Anlieferung-Kessel-mit-Montagearbeiter-1905.jpg",
              caption:
                "Anlieferung des Kessels mit Dampfdom für die Dampfmaschine 1905 (Gemeindearchiv)",
            },
          },
          {
            title: "Visualisierung",
            desc: "Visualisierung Selters-Sprudel Augusta-Victoria, Betriebsgebäude Bahnseite 1905 (Architekturbüro Ritz & Losacker)",
            img: {
              path:
                imgPath +
                "01/h/S126u-Visualisierung-Betriebsgebäude-Bahnseite-1905.jpg",
              caption:
                "Visualisierung Selters-Sprudel Augusta-Victoria, Betriebsgebäude Bahnseite 1905 (Architekturbüro Ritz & Losacker)",
            },
          },
        ],
      },
      {
        title: "Bildergalerie",
        type: "Galerie",
        desc: "Bildsammlung Augusta Viktoria",
        data: [
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S113u Selters-Sprudel Auguste-Victoria - Flußkarte der Lahn 1906.jpg",
              caption:
                "Selters-Sprudel Augusta-Victoria - Flußkarte der Lahn 1906 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S124o Betriebsglände Neubau Baustelle 1905.jpg",
              caption:
                "Betriebsgelände Selters-Sprudel Augusta-Victoria - Neubau. Baustelle 1905 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S125o Anlieferung Kessel 1905.jpg",
              caption:
                "Anlieferung des Kessels mit Dampfdom für die Dampfmaschine 1905 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S126o Visualisierung Betriebsgebäude Lahnseite 1905.jpg",
              caption:
                "Visualisierung Selters-Sprudel Augusta-Victoria, Betriebsgebäude Lahnseite 1905 (Architekturbüro Ritz & Losacker)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S132o Füllraum 1910.jpg",
              caption:
                "Füllraum Selters-Sprudel Augusta-Victoria 1910 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S132u Flaschenvorbereitung 1910.jpg",
              caption: "Flaschenvorbereitung 1910 (Löhnberger Bilderbogen)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S133o Belegschaft Selters-Sprudel 1910.jpg",
              caption:
                "Belegschaft Selters-Sprudel Augusta-Victoria 1910 (Löhnberger Bilderbogen)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S138u Dampfmaschine Kohlensäurewerk 1911.jpg",
              caption:
                "Dampfmaschine Kohlensäurewerk Selters-Sprudel Augusta-Victoria 1911 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S139o Anlage zur Kohlensäuregewinnung 1911.jpg",
              caption:
                "Anlage zur Kohlensäuregewinnung Selters-Sprudel Augusta-Victoria 1911 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath + "01/g/" + "S139u Kohlendioxid-Flaschenlager 1911.jpg",
              caption:
                "Kohlendioxid-Flaschenlager Selters-Sprudel Augusta-Victoria 1911 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S140o Flaschenreinigung 1911.jpg",
              caption:
                "Flaschenreinigung Selters-Sprudel Augusta-Victoria 1911 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S140u Flaschenabfüllung 1911.jpg",
              caption:
                "Flaschenabfüllung Selters-Sprudel Augusta-Victoria 1911 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S141o Voll- und Leergutlager 1911.jpg",
              caption:
                "Voll- und Leergutlager Selters-Sprudel Augusta-Victoria 1911 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S141u Selters-Sprudel von der Laneburg aus 1911.jpg",
              caption:
                "Selters-Sprudel Augusta-Victoria von der Laneburg aus gesehen 1911 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S142o Leergutaußenlager 1911.jpg",
              caption:
                "Leergutaußenlager Selters-Sprudel Augusta-Victoria 1911 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S149u Messestand 1927.jpg",
              caption:
                "Messestand Landwirtschaftsausstellung Fulda vom 15. - 17.7.1927 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S151o Selters-Sprudel Augusta-Victoria Bahnseite 1927.jpg",
              caption:
                "Selters-Sprudel Augusta-Victoria Bahnseite 1927 (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S151u Selters-Sprudel Augusta-Victoria mit Bahnanschluss 1927.jpg",
              caption:
                "Selters-Sprudel Augusta-Victoria mit Bahnanschluss 1927 (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath + "01/g/" + "S152u Füllraum Slters-Sprudel 1927.jpg",
              caption:
                "Füllraum Selters-Sprudel Augusta-Victoria 1927 (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S153o Wasch- und Füllraum 1927.jpg",
              caption:
                "Wasch- und Füllraum, Selters-Sprudel Augusta-Victoria 1927 (Hessisches Wirtschaftsarchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S153u Flaschenversand 1927.jpg",
              caption:
                "Flaschenversand, Selters-Sprudel Augusta Victoria 1927 (Hessisches Wirtschaftsarchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "01/g/" +
                "S142u Einziges Foto vom Ursprungswerk 1898 von 1911.jpg",
              caption:
                "Einziges Foto vom Ursprungswerk 1989, dieses war 1911 noch nicht abgebrochen. (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "01/g/" + "S128 Messestand 1906.jpg",
              caption:
                "Messestand Selters-Sprudel Augusta-Victoria 1906 (Gemeindearchiv)",
            },
          },
        ],
      },
    ],
  },
  // 02 Augusta Viktoria Kohlensäurewerk 1902
  {
    title: "Augusta Viktoria Kohlensäurewerk 1902",
    desc: "Augusta Viktoria Kohlensäurewerk 1902",
    latLng: [45, -157],
    icon: imgPath + "02/m/marker.png",
    iconSize: 250,
    img: { path: imgPath + "02/m/card.jpg", height: 250 },
    data: [
      {
        title: "3D Video Kohlensäurewerk Zweiflügig",
        type: "VideoItem",
        desc: "",
        data: [
          {
            video: {
              src: imgPath + "02/v/av-kohlensaurewerk-v21.mp4",
              poster: imgPath + "02/m/card.jpg",
            },
          },
        ],
      },
      {
        title: "3D Video Kohlensäurewerk Einflügig",
        type: "VideoItem",
        desc: "",
        data: [
          {
            video: {
              src: imgPath + "02/v/av-kohlensaurewerk-v11.mp4",
              poster: imgPath + "02/m/card.jpg",
            },
          },
        ],
      },
      {
        title: "Das Kohlensäurewerk von 1902",
        type: "HoverImage",
        desc: "Vergleich einer Aufnahme von 1927 zu heute.",
        data: [
          {
            title: "Foto von 1927",
            desc: "Kohlensäurewerk Selter-Sprudel Augusta-Victoria 1927  (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            img: {
              path: imgPath + "02/h/S154o Kohlensäurewerk 1927.jpg",
              caption:
                "Kohlensäurewerk Selter-Sprudel Augusta-Victoria 1927  (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
          {
            title: "Foto von 2020",
            desc: "Kohlensäurewerk von 1902 (Bonn Medien 2020)",
            img: {
              path: imgPath + "02/h/S164u Kohlensäurewerk von 1902.jpg",
              caption: "Kohlensäurewerk von 1902 (Bonn Medien 2020)",
            },
          },
        ],
      },
      {
        title: "Bildreihe Dokumente",
        type: "ImageSlider",
        desc: "Historische Dokumente des Selters-Sprudel Augusta-Victoria",
        data: [
          {
            img: {
              path:
                imgPath +
                "02/s/" +
                "S115o Kohlesäurewerk Selters-Sprudel Augusta-Victoria.jpg",
              caption:
                "Kohlensäurewerk Selters-Sprudel Augusta-Victoria (Bauantrag im Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "02/s/" +
                "S115u Mineralwasserleitung durch die Lahn zum Kohlensäurewerk 1902.jpg",
              caption:
                "Mineralwasserleitung durch die Lahn zum Kohlensäurewerk 1902 Katasterkarte 1915 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "02/s/" +
                "S121u Kohlensäurewerk Deißmann & Roepke Planung 193.jpg",
              caption:
                "Kohlensäurewerk Deißmann & Roepke, Planung 1903 (Gemeindearchiv)",
            },
          },
        ],
      },
      {
        title: "Bildergalerie",
        type: "Galerie",
        desc: "Bildsammlung Kohlensäurewerk",
        data: [
          {
            img: {
              path:
                imgPath +
                "02/g/" +
                "S116o Visualisierung Kohlensäurewerk Planung zweiflüglig 1902, Luftaufnahme.jpg",
              caption:
                "Visualisierung Kohlensäurewerk Selters-Sprudel Augusta-Victoria, Planung 1902 zweiflügelig - Perspektive ebenerdig (Architekturbüro Ritz & Losacker)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "02/g/" +
                "S154u Dieselmotor im Kohlensäurewerk 1927.jpg",
              caption:
                "Selters-Sprudel Augusta-Victoria Dieselmotor im Kohlensäurewerk 1927  (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "02/g/" +
                "S155o Maschinenraum im Kohlensäurewerk 1927.jpg",
              caption:
                "Selters-Sprudel Augusta-Victoria Maschinenraum im Kohlensäurewerk 1927 (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "02/g/" +
                "S155u Frostschutz- und Kühlwaggons 1927.jpg",
              caption:
                "Selters-Sprudel Augusta-Victoria Frostschutz- und Kühlwaggons 1927 (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "02/g/" +
                "S156o LKW Fuhrpark Selters-Sprudel 1927.jpg",
              caption:
                "LKW mit dem Fahrer Karl Menger (links) und dem Leiter des Fuhrparks Mathai Selter-Sprudel Augusta-Victoria 1927 (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath + "02/g/" + "S156u LKWs in der Schlosskurfe 1927.jpg",
              caption:
                "Drei LKW in der Schlosskurve unter der Laneburg 1927 (Hessisches Wirtschaftsarchiv)",
            },
          },
          {
            img: {
              path: imgPath + "02/g/" + "S157o LKWs am Bahnübergang 1927.jpg",
              caption:
                "Drei LKW am Bahnübergang von der Laneburg 1927 (Hessisches Wirtschaftsarchiv/Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath + "02/g/" + "S157u LKWs auf der Landstraße 1927.jpg",
              caption:
                "Drei LKW auf der Landstraße 1927 (Hessisches Wirtschaftsarchiv)",
            },
          },
          {
            img: {
              path: imgPath + "02/g/" + "S159ol Messestand April-Mai 1929.jpg",
              caption:
                "Messestand bei der 2. Süddeutschen Gastwirtemesse in Karlsruhe, April-Mai 1929 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "02/g/" + "S159or Messestand 1928_29.jpg",
              caption: "Messestand 1928/29 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "02/g/" + "S159u Messestand 1929.jpg",
              caption: "Messestand 1929 (Gemeindearchiv)",
            },
          },
        ],
      },
    ],
  },
  // 03 Augusta Viktoria linke Lahnseite
  {
    title: "Augusta Viktoria linke Lahnseite",
    desc: "Augusta Viktoria linke Lahnseite",
    latLng: [50, -131],
    icon: imgPath + "03/m/marker.png",
    iconSize: 250,
    img: { path: imgPath + "03/m/card.jpg", height: 250 },
    data: [
      {
        title: "3D Video",
        type: "VideoItem",
        desc: "",
        data: [
          {
            video: {
              src: imgPath + "03/v/av-linke-lahnseite.mp4",
              poster: imgPath + "03/m/card.jpg",
            },
          },
        ],
      },
      {
        title: "Fotos Brunnenhaus",
        type: "TextImage",
        desc: "Visualisierung Brunnenhaus und ehemaliger Standort",
        data: [
          {
            title: "Brunnenhaus",
            desc: "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Sprudel 1898 - Lahnseite (Architekturbüro Ritz & Losacker)",
            img: {
              path: imgPath + "03/i/S108o Visualisierung Brunnenhaus 1898.jpg",
              caption:
                "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Sprudel 1898 - Lahnseite (Architekturbüro Ritz & Losacker)",
            },
          },
          {
            title: "Brunnenhaus, Lahnseite",
            desc: "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Sprudel 1898 (Architekturbüro Ritz & Losacker)",
            img: {
              path:
                imgPath +
                "03/i/S108u Visualisierung Brunnenhaus 1898, Lahnseite.jpg",
              caption:
                "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Sprudel 1898 (Architekturbüro Ritz & Losacker)",
            },
          },
          {
            title: "Standort 1898",
            desc: "Standort des ehemaligen Brunnenhauses von 1898 (Gemeinde Löhnberg 2020)",
            img: {
              path:
                imgPath + "03/i/S163u Standort ehemaliges Brunnenhaus 1898.jpg",
              caption:
                "Standort des ehemaligen Brunnenhauses von 1898 (Gemeinde Löhnberg 2020)",
            },
          },
          {
            title: "Brunnenhaus, Lahnseite",
            desc: "Standort des ehemaligen Deißmann-Brunnens von 1903 (Gemeinde Löhnberg 2020)",
            img: {
              path:
                imgPath +
                "03/i/S164o Standort ehemaliger Deißmann-Brunnen 1903.jpg",
              caption:
                "Standort des ehemaligen Deißmann-Brunnens von 1903 (Gemeinde Löhnberg 2020)",
            },
          },
        ],
      },
      {
        title: "Bildreihe Dokumente",
        type: "ImageSlider",
        desc: "Historische Dokumente Brunnenhaus",
        data: [
          {
            img: {
              path:
                imgPath +
                "03/s/" +
                "S106o Bauzeichnung Brunnenhaus Wilhelmsbrunnen 1887.jpg",
              caption:
                "Bauzeichnung Brunnenhaus Wilhelmsbrunnen 18878 (Gemeindearchiv) Lage des Brunnenhauses Wilhelomsbrunnen auf Karte von 1887 (HHStAW 3011/2 1673)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "03/s/" +
                "S106u Lageplan Brunnenhaus Wilhelmsbrunnen 1887.jpg",
              caption:
                "Karte mit Wilhelmsbrunnen und neuem Brunnen (später Selters-Sprudel Augusta-Victoria) 1896 (HHStAW 412/129)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "03/s/" +
                "S107o Karte Wilhelmsbrunnen und neuem Brunnen 1896.jpg",
              caption:
                "Brunnenhaus Selterser-Mineralquellen-Sprudel 1896 (erste Planung)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "03/s/" +
                "S107ul Brunnenhaus Selterser-Mineralquellen-Sprudel 1896 (erste Planung).jpg",
              caption:
                "Brunnenhaus Selterser-Mineralquellen-Sprudel 1896 (erste Planung)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "03/s/" +
                "S107ur Brunnenhaus Selterser-Mineralquellen-Sprudel 1896 (Bauausführung).jpg",
              caption:
                "Brunnenhaus Selterser-Mineralquellen-Sprudel 1896 (Bauausführung)",
            },
          },
        ],
      },
    ],
  },
  // 04 Mineralbrunnen - Gesellschaft 1888
  {
    title: "Mineralbrunnen - Gesellschaft 1888",
    desc: "Mineralbrunnen - Gesellschaft 1888",
    latLng: [72, -110],
    icon: imgPath + "04/m/marker.png",
    iconSize: 250,
    img: { path: imgPath + "04/m/card.jpg", height: 250 },
    data: [
      {
        title: "3D Video",
        type: "VideoItem",
        desc: "",
        data: [
          {
            video: {
              src: imgPath + "04/v/selters1888-v1.mp4",
              poster: imgPath + "04/m/card.jpg",
            },
          },
        ],
      },
      {
        title: "Visualisierung Brunnenhaus",
        type: "TextImage",
        desc: "Brunnenhaus Selterser-Mineralbrunnen-Gesellschaft 1890",
        data: [
          {
            title: "Brunnenhaus",
            desc: "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Gesellschaft Planung 1888 (Architekturbüro Ritz & Losacker)",
            img: {
              path: imgPath + "04/i/" + "S73o Visu Brunnenhaus 1888.jpg",
              caption:
                "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Gesellschaft Planung 1888 (Architekturbüro Ritz & Losacker)",
            },
          },
          {
            title: "Brunnenhaus",
            desc: "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Gesellschaft 1890, Rückseite (Architekturbüro Ritz & Losacker)",
            img: {
              path: imgPath + "04/i/S74o Visu Brunnenhaus 1890 Rückseite.jpg",
              caption:
                "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Gesellschaft 1890, Rückseite (Architekturbüro Ritz & Losacker)",
            },
          },
          {
            title: "Brunnenhaus",
            desc: "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Gesellschaft 1890, Lahnseite (Architekturbüro Ritz & Losacker)",
            img: {
              path: imgPath + "04/i/S74u Visu Brunnenhaus 1890 Lahnseite.jpg",
              caption:
                "Visualisierung Brunnenhaus Selterser-Mineralbrunnen-Gesellschaft 1890, Lahnseite (Architekturbüro Ritz & Losacker)",
            },
          },
        ],
      },
    ],
  },
  // 05 Original Selters 1894
  {
    title: "Original Selters 1894",
    desc: "Original Selters 1894",
    latLng: [72, -88],
    icon: imgPath + "05/m/marker.png",
    iconSize: 250,
    img: { path: imgPath + "05/m/card.jpg", height: 250 },
    data: [
      {
        title: "3D Video",
        type: "VideoItem",
        desc: "",
        data: [
          {
            video: {
              src: imgPath + "05/v/original-selters-1894-1.mp4",
              poster: imgPath + "05/m/card.jpg",
            },
          },
        ],
      },
      {
        title: "Bildreihe Dokumente",
        type: "ImageSlider",
        desc: "Historische Dokumente",
        data: [
          {
            img: {
              path: imgPath + "05/s/" + "S72o Karte August 1896.jpg",
              caption:
                "Original-Selters-Brunnen und Brunnen von Kempf; Karte August 1896 - Ausschnitt (HHStAW 412/129)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/s/" +
                "S72u Skizze Brunnenhaus Bauantrag 1888.jpg",
              caption:
                "Brunnenhaus Selterser-Mineralbrunnen-Gesellschaft; Bauantrag von 1888 wurde verändert ausgeführt (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "05/s/" + "S73u Brunnenhaus Bauplan 1890.jpg",
              caption:
                "Brunnenhaus Selterser-Mineralbrunnen-Gesellschaft Bauplan 1890 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/s/" +
                "S80o Skizze Erweiterung Brunnenhaus 1894.jpg",
              caption:
                "Erweiterung Brunnenhaus Original-Selters-Brunnen-Bauantrag 1894 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path: imgPath + "05/s/" + "S87o Ausschnitt Karte 1914.jpg",
              caption:
                "Selterser Brunnenversendung, Karte 1914 - Ausschnitt (Gemeindearchiv)",
            },
          },
        ],
      },
      {
        title: "Mineralbrunnen - Gesellschaft 1888",
        type: "HoverImage",
        desc: "Vergleich einer neueren Aufnahme und der Visualisierung.",
        data: [
          {
            title: "Visualisierung",
            desc: "Mineralbrunnen - Gesellschaft 1888",
            img: {
              path:
                imgPath +
                "05/h/721 - 01 - Original Selters 1894 - 2020.03.25.jpg",
              caption: "Visualisierung",
            },
          },
          {
            title: "Foto",
            desc: "Mineralbrunnen - Gesellschaft 188",
            img: {
              path: imgPath + "05/h/dia-braun-480---04.jpg",
              caption: "Mineralbrunnen - Gesellschaft 188",
            },
          },
        ],
      },
      {
        title: "Original-Selters-Brunnen 1894",
        type: "TextImage",
        desc: "Brunnenhaus",
        data: [
          {
            title: "Brunnenhaus Vorderseite",
            desc: "Visualisierung Brunnenhaus Original-Selters-Brunnen 1894, Vorderseite (Architekturbüro Ritz & Losacker)",
            img: {
              path:
                imgPath +
                "05/i/" +
                "S80u Visu-Brunnenhaus 1894 Vorderseite.jpg",
              caption:
                "Visualisierung Brunnenhaus Original-Selters-Brunnen 1894, Vorderseite (Architekturbüro Ritz & Losacker)",
            },
          },
          {
            title: "Brunnenhaus Rückseite",
            desc: "Visualisierung Brunnenhaus Original-Selters-Brunnen 1894, Rückseite (Architekturbüro Ritz & Losacker)",
            img: {
              path:
                imgPath + "05/i/" + "S81o Visu Brunnenhaus 1894 Rückseite.jpg",
              caption:
                "Visualisierung Brunnenhaus Original-Selters-Brunnen 1894, Rückseite (Architekturbüro Ritz & Losacker)",
            },
          },
          {
            title: "Brunnenhaus",
            desc: "Visualisierung Brunnenhaus Original-Selters-Brunnen 1894, Lahnseite (Architekturbüro Ritz & Losacker)",
            img: {
              path:
                imgPath + "05/i/" + "S81u Visu Brunnenhaus 1894 Lahnseite.jpg",
              caption:
                "Visualisierung Brunnenhaus Original-Selters-Brunnen 1894, Lahnseite (Architekturbüro Ritz & Losacker)",
            },
          },
        ],
      },
      {
        title: "Bildergalerie",
        type: "Galerie",
        desc: "Original-Selters-Brunnen 1894",
        data: [
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S88o Hermann Stoll, Henirette Stoll 1920.jpg",
              caption:
                "Selterser Mineralquelle mit 'Fährmann' Herrmann Stoll, ganz rechts Ehefrau Henriette Stoll, ganz links Tochter Emmi mit Hund Lippert ca. 1920 (Volker Stoll)",
            },
          },
          {
            img: {
              path:
                imgPath + "05/g/" + "S88u Brunnenhaus mit Bewohnern 1920.jpg",
              caption:
                "Selterser Mineraquelle mit Bewohnern. Hinten v.l.n.r.: Paul Geier, Emmi Stoll, Friedrich Georg. Vorne v.l.n.r.: Junge mit Fahrrad, Lina Geier mit Arno, Henriette Stoll, Hermann Stoll, Martha Georg mit Edwin ca. 1920 (Volker Stoll)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S89m Gebäude Selters Mineralquelle 1940.jpg",
              caption: "Gebäude Selters Mineralquelle ca. 1940 (Volker Stoll)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S89o Gebäude Selters Mineralquelle 1940.jpg",
              caption: "Gebäude Selters Mineralquelle ca. 1940 (Volker Stoll)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S89ul Gebäude Selters Mineralquelle 1940.jpg",
              caption: "Gebäude Selters Mineralquelle ca. 1940 (Volker Stoll)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S89ur Gebäude Selters Mineralquelle 1940.jpg",
              caption: "Gebäude Selters Mineralquelle ca. 1940 (Volker Stoll)",
            },
          },
          {
            img: {
              path:
                imgPath + "05/g/" + "S90o Alt-Selters im Hochwasser 1968.jpg",
              caption: "Alt-Selters im Lahnhochwasser 1968 (Hermann Braun)",
            },
          },
          {
            img: {
              path: imgPath + "05/g/" + "S90u Alt-Selters 1971.jpg",
              caption: "Alt-Selters an der Lahn 1971 (Hermann Braun)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S91o-Alt-Selters nach Brand Lahnseite 1972.jpg",
              caption:
                "Alt-Selters nach dem Brand 1972, Lahnseite (Hermann Braun)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S91u_Alteslters nach Brand Rückseite 1972.jpg",
              caption:
                "Alt-Selters nach dem Brand 1972, Rückseite (Hermann Braun)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S92o Original-Selters-Brunnen 2019 - stillgelegt.jpg",
              caption:
                "Original-Selters-Brunnen 2019 - stillgelegt (Gemeinde Löhnberg)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S92u Original-Selters-Brunnen 2019 - stillgelegt.jpg",
              caption:
                "Original-Selters-Brunnen 2019 - stillgelegt, Lahn direkt hinter Brunnen (Gemeinde Löhnberg)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "05/g/" +
                "S93o Original-Selters-Brunnen 2020 - komplett abgegraben.jpg",
              caption:
                "Original-Selters-Brunnen 2020 - komplett abgegraben (Gemeinde Löhnberg)",
            },
          },
          {
            img: {
              path: imgPath + "05/g/" + "S93u Brunnen Kempf 2020.jpg",
              caption: "Brunnen Kempf 2020 (Gemeinde Löhnberg)",
            },
          },
        ],
      },
    ],
  },
  // 06 Neuselters Betriebsgebäude 1906
  {
    title: "Neuselters Betriebsgebäude 1906",
    desc: "Neuselters Betriebsgebäude 1906",
    latLng: [76.0, -21],
    icon: imgPath + "06/m/marker.png",
    iconSize: 250,
    img: { path: imgPath + "06/m/card.jpg", height: 250 },
    data: [
      {
        title: "3D Video",
        type: "VideoItem",
        desc: "",
        data: [
          {
            video: {
              src: imgPath + "06/v/neuselters_betriebsgebaeude_1906-1.mp4",
              poster: imgPath + "06/m/card.jpg",
            },
          },
        ],
      },
      {
        title: "Bildreihe Dokumente",
        type: "ImageSlider",
        desc: "Historische Dokumente",
        data: [
          {
            img: {
              path:
                imgPath +
                "06/s/" +
                "S173u Bauzeichnung Neue Selterser Mineralquelle Westseite 1906.jpg",
              caption:
                "Bauzeichnung Neue Selterser Mineralquelle - Westseite 1906 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/s/" +
                "S174o Bauzeichnung Neue Selterser Mineralquelle Nordseite 1906.jpg",
              caption:
                "Bauzeichnung Neue Selterser Mineralquelle - Nordseite 1906 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/s/" +
                "S174u Bauzeichnung Neue Selterser Mineralquelle Südseite 1906.jpg",
              caption:
                "Bauzeichnung Neue Selterser Mineralquelle - Südseite 1906 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/s/" +
                "S177o Außenanlagen Neue Selterser Mineralquelle 1912.jpg",
              caption:
                "Außenanlage Neue Selterser Mineralquelle 1912 (Heimatkundlicher Arbeitskreis Biskirchen/Broschüre S. 17)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/s/" +
                "S180o Brunnenhaus Neu Selterser Mineralqueller Südseite 1920.jpg",
              caption:
                "Brunnenhaus Neue Selterser Mineralquelle - Südseite, Briefkopf 1920 (Ausschnitt) (Gemeinedearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/s/" +
                "S180u Brunnenhaus Neue Selterser Mineralquelle Nordseite 1920.jpg",
              caption:
                "Brunnenhaus Neue Selterser Mineralquelle - Nordseite, Briefkopf 1920 (Ausschnitt) (Gemeinedearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/s/" +
                "S172u Neuer Selterser Sprudel Flusskarte von 1905.jpg",
              caption:
                "Neuer Selterser Sprudel, 'Bretterbude'. Flusskarte von 1905 (Ausschnitt) (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/s/" +
                "S172o Schacht Friedrich, Karte von 1896.jpg",
              caption:
                "Schacht Friedrich, später Neue Selterser Mineralquelle. Karte von 1896 (Ausschnitt) (HHStAW 412/126)",
            },
          },
        ],
      },
      {
        title: "Original-Selters-Brunnen 1894",
        type: "TextImage",
        desc: "Betriebsgebäude",
        data: [
          {
            title: "Visualisierung Brunnenhaus",
            desc: "Visualisierung Brunnenhaus Neue Selterser Mineralquelle nach Bauplan 1906 (Architektenbüro Ritz & Losacker)",
            img: {
              path:
                imgPath +
                "06/i/" +
                "S175o Visualisierung Brunnenhaus Neue Selterser Mineralquelle.jpg",
              caption:
                "Visualisierung Brunnenhaus Neue Selterser Mineralquelle nach Bauplan 1906 (Architektenbüro Ritz & Losacker)",
            },
          },
          {
            title: "Brunnenhaus mit Brunnenschacht",
            desc: "Visualisierung Brunnenhaus mit Brunnenschacht, nach Bauplan 1906 (Architektenbüro Ritz & Losacker)",
            img: {
              path:
                imgPath +
                "06/i/" +
                "S175u Visualisierung Brunnenhaus mit Brunnenschacht.jpg",
              caption:
                "Visualisierung Brunnenhaus mit Brunnenschacht, nach Bauplan 1906 (Architektenbüro Ritz & Losacker)",
            },
          },
          {
            title: "Nordseite",
            desc: "Brunnenhaus Neue Selterser Mineralquelle - Nordseite - Ansichtskarte 1920 (Gemeinedearchiv)",
            img: {
              path:
                imgPath +
                "06/i/" +
                "S181u Brunnenhaus Neue Selterser Mineralquelle Nordseite Ansichtskarte 1920.jpg",
              caption:
                "Brunnenhaus Neue Selterser Mineralquelle - Nordseite - Ansichtskarte 1920 (Gemeinedearchiv)",
            },
          },
          {
            title: "Luftaufnahme",
            desc: "Luftaufnahme ehemaliges Neuselterswerk 2020 (Bonn Medien)",
            img: {
              path:
                imgPath +
                "06/i/" +
                "S188o Luftaufnahme Neuselterswerk 2020.jpg",
              caption:
                "Luftaufnahme ehemaliges Neuselterswerk 2020 (Bonn Medien)",
            },
          },
          {
            title: "Ehemailges Versandgebäude",
            desc: "Ehemailges Versandgebäude in Stockhausen Neue Selterser Mineralquelle (Gemeindearchiv 2020)",
            img: {
              path:
                imgPath +
                "06/i/" +
                "S188u Ehemalieges Versandgebäude in Stockhausen 2020.jpg",
              caption:
                "Ehemailges Versandgebäude in Stockhausen Neue Selterser Mineralquelle (Gemeindearchiv 2020)",
            },
          },
        ],
      },
      {
        title: "Bildergalerie",
        type: "Galerie",
        desc: "Original-Selters-Brunnen 1894",
        data: [
          {
            img: {
              path:
                imgPath + "06/g/" + "S165o Selterser Mineralquelle 2010.jpg",
              caption:
                "Selterser Mineralquelle Augusta Victoria GmbH 2010 (Selters-Sprudel Augusta-Victoria)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/g/" +
                "S165u Selterser Mineralquelle Luftaufnahme 2020.jpg",
              caption:
                "Selterser Mineralquelle Augusta Victoria GmbH im Vordergrund (Bonn Medien 2020)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/g/" +
                "S173o Hochwasser Lahnbeim Bau der Mineralquelle 1906.jpg",
              caption:
                "Hochwasser der Lahn während der Bauphase der Neuen Selterser Mineralquelle 1906 (Gemeindearchiv)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/g/" +
                "S177u Flaschenhebeanlage Neue Selterser Mineralquelle 1910.jpg",
              caption:
                "Flaschenhebeanlage Neue Selterser Mineralquelle 1910 (Selters-Sprudel Augusta-Victoria)",
            },
          },
          {
            img: {
              path: imgPath + "06/g/" + "S178o Füllhalle 1912.jpg",
              caption:
                "Füllhalle - Neue Selterser Mineralquelle 1912 (Heimatkundlicher Arbeitskreis Biskirchen/Broschüre S. 19)",
            },
          },
          {
            img: {
              path:
                imgPath + "06/g/" + "S178u Antriebsmotor für Transmission.jpg",
              caption: "Antriebsmotor für Transmission",
            },
          },
          {
            img: {
              path: imgPath + "06/g/" + "S179o Schlosserei 1912.jpg",
              caption:
                "Schlosserei - Neue Selterser Mineralquelle 1912 (Hessisches Wirtschaftsarchiv/Broschüre S. 13)",
            },
          },
          {
            img: {
              path: imgPath + "06/g/" + "S179u Versand in Stockhausen.jpg",
              caption:
                "Versand in Stockhausen Neue Selterser Mineralquelle (Hessisches Wirtschaftsarchiv/Broschüre S. 15)",
            },
          },
          {
            img: {
              path:
                imgPath +
                "06/g/" +
                "S182o LKW einer Vertriebsstelle der Neuen Selterser Mineralquelle 1920.jpg",
              caption:
                "LKW einer Vertriebsstelle der Neuen Selterser Mineralquelle in Oberlahnstein 1920",
            },
          },
          {
            img: {
              path: imgPath + "06/g/" + "S184o Versand in Stockhausen 1925.jpg",
              caption:
                "Versand in Stockhausen. Neue Selterser Mineralquelle 1925 (Hessisches Wirtschaftsarchiv)",
            },
          },
        ],
      },
    ],
  },

  /*
  {
    title: "Dummy",
    desc: "Kurze Beschreibung zum POI, lorem ipsum",
    latLng: [-80, -140],
    icon: imgPath + "house-marker.png",
    iconSize:300,
    img: { path: imgPath + "card2.jpg", height: 250 },
    data: [
      {
        title: "Test TextItem",
        type: "TextItem",
        data: [
          {
            title: "Überschrift 1",
            desc:
              "Testeintrag ohne zugehörige BilderLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
          },
          {
            title: "Überschrift 1",
            desc:
              "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr",
          },
          {
            title: "Überschrift 1",
            desc:
              "sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
          },
        ],
      },
      {
        title: "Test TextImage",
        type: "TextImage",
        desc: "Testeintrag 2 mit Bild",
        data: [
          {
            title: "Bildüberschrift 1",
            desc: "Text über Bild",
            img: {
              path: imgPath + "TextImage.jpg",
              caption: "Testbild mit Bildunterschrift",
            },
          },
          {
            title: "Bildüberschrift 2",
            desc: "Text über Bild hochkant",
            img: {
              path: imgPath + "full2_on.jpg",
              caption: "Testbild mit Bildunterschrift",
            },
          },
        ],
      },
      {
        title: "Test HoverImage",
        type: "HoverImage",
        desc: "Testeintrag 3 Hoverbild",
        data: [
          {
            title: "Bildüberschrift 1",
            desc: "Text über Bild",
            img: {
              path: imgPath + "hov0.jpg",
              caption: "Testbild mit Bildunterschrift",
            },
          },
          {
            title: "Bildüberschrift 2 Hover",
            desc: "Text über Bild hochkant",
            img: {
              path: imgPath + "hov1.jpg",
              caption: "Testbild mit Bildunterschrift für Hovertext",
            },
          },
        ],
      },
      {
        title: "Test ImageSlider",
        type: "ImageSlider",
        desc: "Testeintrag 4 für den ImageSlider",
        data: [
          {
            img: {
              path: imgPath + "card01.jpg",
              caption: "Testbild mit Bildunterschrift 1",
            },
          },
          {
            img: {
              path: imgPath + "card2.jpg",
              caption: "Testbild mit Bildunterschrift 2",
            },
          },
          {
            img: {
              path: imgPath + "card3.jpg",
              caption: "Testbild mit Bildunterschrift 3",
            },
          },
          {
            img: {
              path: imgPath + "card4.jpg",
              caption: "Testbild mit Bildunterschrift 4",
            },
          },
          {
            img: {
              path: imgPath + "card2.jpg",
              caption: "Testbild mit Bildunterschrift 5",
            },
          },
        ],
      },
      {
        title: "Test Galerie",
        type: "Galerie",
        desc: "Testeintrag 5 ohne Daten",
        data: [
          {
            img: {
              path: imgPath + "card01.jpg",
              caption: "Testbild mit Bildunterschrift 1",
            },
          },
          {
            img: {
              path: imgPath + "card2.jpg",
              caption: "Testbild mit Bildunterschrift 2",
            },
          },
          {
            img: {
              path: imgPath + "card3.jpg",
              caption: "Testbild mit Bildunterschrift 3",
            },
          },
          {
            img: {
              path: imgPath + "card4.jpg",
              caption: "Testbild mit Bildunterschrift 4",
            },
          },
          {
            img: {
              path: imgPath + "card01.jpg",
              caption: "Testbild mit Bildunterschrift 1",
            },
          },
          {
            img: {
              path: imgPath + "card2.jpg",
              caption: "Testbild mit Bildunterschrift 2",
            },
          },
          {
            img: {
              path: imgPath + "card3.jpg",
              caption: "Testbild mit Bildunterschrift 3",
            },
          },
          {
            img: {
              path: imgPath + "card4.jpg",
              caption: "Testbild mit Bildunterschrift 4",
            },
          },
          {
            img: {
              path: imgPath + "card01.jpg",
              caption: "Testbild mit Bildunterschrift 1",
            },
          },
          {
            img: {
              path: imgPath + "card2.jpg",
              caption: "Testbild mit Bildunterschrift 2",
            },
          },
          {
            img: {
              path: imgPath + "card3.jpg",
              caption: "Testbild mit Bildunterschrift 3",
            },
          },
          {
            img: {
              path: imgPath + "card4.jpg",
              caption: "Testbild mit Bildunterschrift 4",
            },
          },
          {
            img: {
              path: imgPath + "card01.jpg",
              caption: "Testbild mit Bildunterschrift 1",
            },
          },
          {
            img: {
              path: imgPath + "card2.jpg",
              caption: "Testbild mit Bildunterschrift 2",
            },
          },
          {
            img: {
              path: imgPath + "card3.jpg",
              caption: "Testbild mit Bildunterschrift 3",
            },
          },
          {
            img: {
              path: imgPath + "card4.jpg",
              caption: "Testbild mit Bildunterschrift 4",
            },
          },
        ],
      },
      {
        title: "Test VideoItem",
        type: "VideoItem",
        desc: "Testeintrag 6 ohne Daten",
      },
      { title: "Test Cad3d", type: "Cad3d", desc: "Testeintrag 7 ohne Daten" },
    ],
  },*/
];
