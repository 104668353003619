
















































































































import { Component, Vue } from "vue-property-decorator";
import { markers } from "@/data/data";
import { Marker } from "@/models/Marker";

@Component({
  components: {},
})
export default class Home extends Vue {
  private markers: Marker[] = markers;
}
